var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"py-4 px-0",attrs:{"light":"","color":"#f2f3f4","height":"100%"}},[_c('v-row',_vm._l((_vm.photoElements),function(photo,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"6","md":"4","lg":"3","align":"center"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticStyle:{"cursor":"pointer"}},[_c('v-card',{staticClass:"gallery-card pointer pa-0",attrs:{"elevation":"0","tile":""}},[_c('v-img',{style:({
                'opacity': hover ? '0.65' : '1.0',
                'transition': 'opacity 0.3s ease-in-out'
              }),attrs:{"contain":"","src":photo.previewSrc,"aspect-ratio":1.6},on:{"click":function($event){_vm.clickedPic = i}}})],1),_c('div',{staticClass:"mt-2 pb-1 px-0 text-subtitle-2",staticStyle:{"text-align":"center"},on:{"click":function($event){_vm.clickedPic = i}}},[_vm._v(" "+_vm._s(photo.title)+" ")])],1)]}}],null,true)})],1)}),1)],1),_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.showClickedPic),callback:function ($$v) {_vm.showClickedPic=$$v},expression:"showClickedPic"}},[_c('v-sheet',{staticClass:"d-flex",class:{ 'pa-4': !_vm.$vuetify.breakpoint.mdAndUp, 'pa-12': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"width":"100%","height":"100%","color":"rgba(0,0,0,0.8)"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-btn',{staticClass:"gallery-dialog-close-button",attrs:{"color":"red","fab":"","elevation":hover ? 12 : 3,"small":""},on:{"click":function($event){_vm.clickedPic = undefined}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-close ")])],1)]}}])}),_c('v-row',{staticStyle:{"position":"relative","max-width":"100%","height":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex",staticStyle:{"height":"100%"},attrs:{"cols":"12"}},[_c('v-img',{attrs:{"src":_vm.clickedPic !== undefined ? _vm.photoElements[_vm.clickedPic].src : '',"max-height":"100%","contain":"","position":"center center"}},[_c('v-container',{staticClass:"fill-height pa-0",attrs:{"fluid":""}},[_c('div',{staticClass:"gallery-dialog-navigaion"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-btn',{attrs:{"fab":"","height":"36","width":"36","disabled":_vm.clickedPic === undefined || _vm.clickedPic === 0,"color":"#00000088","elevation":hover ? 12 : 3},on:{"click":function($event){_vm.clickedPic--}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-chevron-left ")])],1)]}}])})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-btn',{attrs:{"fab":"","height":"36","width":"36","disabled":_vm.clickedPic === undefined || _vm.clickedPic === _vm.photoElements.length - 1,"color":"#00000088","elevation":hover ? 12 : 3},on:{"click":function($event){_vm.clickedPic++}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-chevron-right ")])],1)]}}])})],1)],1)])],1)],1),_c('v-col',{staticStyle:{"position":"absolute","width":"100%","bottom":"0px"},attrs:{"cols":"12"}},[(_vm.clickedPic !== undefined)?_c('v-row',{staticClass:"align-self-end",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-left",staticStyle:{"backdrop-filter":"blur(4px)"},attrs:{"cols":"12"}},[(_vm.photoElements[_vm.clickedPic].descriptionTitle !== null)?_c('span',{class:{
                    'text-h6': _vm.$vuetify.breakpoint.xs,
                    'text-h5': !_vm.$vuetify.breakpoint.xs,
                  },staticStyle:{"display":"block","background-color":"#ffffffbb","padding":"6px 12px 6px 12px"}},[_vm._v(" "+_vm._s(_vm.photoElements[_vm.clickedPic].descriptionTitle)+" ")]):_vm._e(),(_vm.photoElements[_vm.clickedPic].description !== null)?_c('span',{class:{
                    'text-body-2': _vm.$vuetify.breakpoint.xs
                  },staticStyle:{"display":"block","background-color":"#ffffffbb","padding":"6px 12px 6px 12px"}},[_c('span',{staticStyle:{"display":"inline-block"}},[_vm._v(" "+_vm._s(_vm.photoElements[_vm.clickedPic].description)+" ")]),(_vm.photoElements[_vm.clickedPic].href !== null)?_c('v-hover',{staticClass:"ml-4",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('v-btn',{attrs:{"href":_vm.photoElements[_vm.clickedPic].href,"target":"_blank","color":"transparent","elevation":hover ? 1 : 0}},[_vm._v(" Mehr "),_c('v-icon',[_vm._v(" mdi-launch ")])],1)]}}],null,false,1770726286)}):_vm._e()],1):_vm._e()])],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }