<template>
  <v-app
    v-resize="onResize"
  >
    <v-app-bar
      app
      color="#242021"
      dark
      :height="windowHeight + 100"
      elevation="16"
      :style="{
        'transform': 'translateY('+ computedAppBarTranslateY +')'
      }"
    >
      <v-row>
        <v-col
          cols="12"
          :style="{
            'height': windowHeight+'px' 
          }"
        >
          <v-row
            no-gutters
            class="fill-height"
          >
            <v-col
              cols="12"
              height="100"
            >
              <v-btn
                icon
                @click="toggleMenu(false)"
              >
                <v-icon
                  large
                >
                  mdi-close-thick
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
            >
              <v-row>
                <v-col
                  v-for="(menuItem, i) in menuItems"
                  :key="i"
                  cols="12"
                  class="text-center text-h4"
                  :class="{
                    'menu-item': menuItem.routeName !== currentRouteName,
                    'menu-item-active': menuItem.routeName === currentRouteName
                  }"
                  @click="menuItem.routeName === currentRouteName ? null : openPage(menuItem.routeName)"
                >
                  {{ menuItem.text }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          height="100"
        >
          <v-row
            no-gutters
            align="center"
          >
            <v-btn
              icon
              @click="toggleMenu(true)"
            >
              <v-icon
                large
              >
                mdi-menu
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-img
              contain
              src="/logos/logo.svg"
              :height="computedLogoHeight"
              max-width="75%"
              position="center right"
              @click="currentRouteName === 'Home' ? null : openPage('Home')"
              :style="{
                'cursor': currentRouteName === 'Home' ? 'default' : 'pointer'
              }"
            >
            </v-img>
          </v-row>
        </v-col>
      </v-row>
      <span
        class="kontakt-stoerer elevation-2 pa-3"
      >
        <v-col
          cols="12"
          class="pa-1"
        >
          <v-btn
            icon
            href="mailto:c@ball-design.de"
            target="_blank"
            small
          >
            <v-icon
              color="#c8242b"
            >
              mdi-email
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          class="pa-1"
        >
          <v-btn
            icon
            href="tel:+496221411554"
            target="_blank"
            small
          >
            <v-icon
              color="#c8242b"
            >
              mdi-phone
            </v-icon>
          </v-btn>
        </v-col>
      </span>
    </v-app-bar>
    <v-main
      style="padding-top: 100px;"
    >
      <router-view>
      </router-view>
    </v-main>
    <span
      class="impress-link-container pa-4"
      :class="{
        'impress-link-container-center': $vuetify.breakpoint.xsOnly,
        'impress-link-container-right': !$vuetify.breakpoint.xsOnly,
      }"
    >
      <span
        :class="{
          'impress-link': currentRouteName !== 'Contact',
          'impress-link-disabled': currentRouteName === 'Contact'
        }"
        class="text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1"
        @click="currentRouteName === 'Contact' ? null : openPage('Contact')"
      >
        IMPRESSUM | DATENSCHUTZ
      </span>
    </span>
  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {
  //  Home,
  },
  data: () => ({
    windowHeight: 0,
    showMenu: false,
    menuItems: [
      { "text": "HOME", "routeName": "Home" },
      { "text": "LEISTUNG", "routeName": "Service" },
      { "text": "PORTFOLIO", "routeName": "Portfolio" },
      { "text": "ZUR PERSON", "routeName": "AboutMe" },
      { "text": "KONTAKT", "routeName": "Contact" }
    ]
  }),
  created () {
  },
  mounted () {
    document.addEventListener('touchmove', function(event) {
      event = event.originalEvent || event;
      if(event.scale !== 1) {
        event.preventDefault()
      }
    }, false)
    this.onResize()
  },
  methods: {
    onResize () {
      this.windowHeight = window.innerHeight
    },
    toggleMenu(doOpen) {
      this.showMenu = doOpen
    },
    openPage (routeName) {
      this.$router.push({ name: routeName })
      this.toggleMenu(false)
    }
  },
  computed: {
    computedAppBarTranslateY() {
      return this.showMenu ? '0px' : '-'+this.windowHeight+'px';
    },
    computedLogoHeight() {
      if (this.$vuetify.breakpoint.xsOnly) {
        return 20
      } else {
        return 30
      }
    },
    currentRouteName() {
      return this.$route.name;
    }
  }
};
</script>

<style>
body{
  touch-action: pan-x pan-y !important;
}
.menu-item {
  cursor: pointer;
  transition: color 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out;
}
.menu-item:hover {
  color: #c8242b; 
}
.menu-item-active {
  color: #c8242b;
  text-decoration: underline;
}
.kontakt-stoerer {
  position: absolute;
  display: block;
  right: 16px;
  bottom: 0px;
  border-radius: 0px 0px 4px 4px;
  background-color: #f2f3f4;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
}
.impress-link-container {
  position: absolute;
  display: inline-block;
  bottom: 0px;
  white-space: nowrap;
}
.impress-link-container-right {
  right: 0px;
}
.impress-link-container-center {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.impress-link-disabled {
  position: relative;
  cursor: default;
  color: #a5a5a5;
  transition: color 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out;
  z-index: 1;
}

.impress-link {
  position: relative;
  cursor: pointer;
  color: #555555;
  transition: color 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out;
  z-index: 1;
}
.impress-link:hover {
  color: #ffffff;
}

.impress-link::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 2px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #c8242b;
  z-index: -1;
  transition: height 0.2s ease-out;
  -webkit-transition: height 0.2s ease-out;
}
.impress-link:hover::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  bottom: 0px;
  background-color: #c8242b;
}
</style>
