<template>
  <v-container>
    <v-row
      class="py-12 px-4 px-sm-8 px-md-0 px-lg-0 px-xl-0"
    >
      <v-col
        class="
          col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4
          pb-12 pb-sm-12 pb-md-0 pb-lg-0 pb-xl-0
        "  
      >
        <v-row>
          <v-col
            cols="12"
            class="
              font-weight-medium
              text-h4 text-sm-h3 text-md-h2 text-lg-h2 text-xl-h1
            "
          >
            Kontakt
          </v-col>
          <v-col
            cols="12"
          >
            <strong>Christine Ball</strong><br />
            <strong>Grafik-Design</strong><br />
            Amselgasse 6<br />
            69121 Heidelberg<br />
            Telefon: <a
              href="tel:+496221411554"
              target="_blank"
              class="no-text-decoration"
            >
              06221 411554
            </a><br />
            E-Mail: <a
              href="mailto:c@ball-design.de"
              target="_blank"
              class="no-text-decoration"
            >
              c@ball-design.de
            </a><br />
            <br />
            UST-IDNR: DE151429936
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8"
      >
        <v-row>
          <v-col
            cols="12"
            class="font-weight-medium text-h4 text-sm-h3 text-md-h2 text-lg-h2 text-xl-h1"
          >
            Impressum
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Angaben gemäß § 5 TMG
          </v-col>
          <v-col
            cols="12"
          >
            Christine Ball<br />
            Grafik-Design<br />
            Amselgasse 6<br />
            69121 Heidelberg
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Umsatzsteuer-ID
          </v-col>
          <v-col
            cols="12"
          >
            Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br />
            DE151429936
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Verbraucher*innenstreitbeilegung / Universalschlichtungsstelle
          </v-col>
          <v-col
            cols="12"
          >
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucher*innenschlichtungsstelle teilzunehmen.
          </v-col>
          <v-col
            cols="12"
            class="py-12"
          >
            <v-divider />
          </v-col>
          <v-col
            cols="12"
            class="font-weight-medium text-h4 text-sm-h3 text-md-h2 text-lg-h2 text-xl-h1"
          >
            Datenschutzerklärung
          </v-col>
          <v-col
            cols="12"
            class="text-h5 text-md-h4"
          >
            1. Datenschutz auf einen Blick
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Allgemeine Hinweise
          </v-col>
          <v-col
            cols="12"
          >
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
            passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen
            Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Datenerfassung auf dieser Website
          </v-col>
          <v-col
            cols="12"
            class="font-weight-bold"
          >
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </v-col>
          <v-col
            cols="12"
          >
            Die Datenverarbeitung auf dieser Website erfolgt durch die Websitebetreiberin. Deren Kontaktdaten
            können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
            entnehmen.
          </v-col>
          <v-col
            cols="12"
            class="font-weight-bold"
          >
            Wie erfassen wir Ihre Daten?
          </v-col>
          <v-col
            cols="12"
          >
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um
            Daten handeln, die Sie in ein Kontaktformular eingeben.
          </v-col>
          <v-col
            cols="12"
          >
            Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit
            des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.
          </v-col>
          <v-col
            cols="12"
            class="font-weight-bold"
          >
            Wofür nutzen wir Ihre Daten?
          </v-col>
          <v-col
            cols="12"
          >
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
            Daten können zur Analyse Ihres Nutzungsverhaltens verwendet werden.
          </v-col>
          <v-col
            cols="12"
            class="font-weight-bold"
          >
            Welche Rechte haben Sie bezüglich Ihrer Daten?
          </v-col>
          <v-col
            cols="12"
          >
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger*in und Zweck Ihrer
            gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben,
            können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
            bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
          </v-col>
          <v-col
            cols="12"
          >
            Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
            Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.
          </v-col>
          <v-col
            cols="12"
            class="text-h5 text-md-h4"
          >
            2. Hosting
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            IONOS
          </v-col>
          <v-col
            cols="12"
          >
            Wir hosten unsere Website bei IONOS SE. Anbieterin ist die IONOS SE, Elgendorfer Str. 57, 56410
            Montabaur (nachfolgend: IONOS). Wenn Sie unsere Website besuchen, erfasst IONOS verschiedene
            Logfiles inklusive Ihrer IP-Adressen. Details entnehmen Sie der Datenschutzerklärung von IONOS:
          </v-col>
          <v-col
            cols="12"
          >
            <a target="_blank" class="no-text-decoration" href="https://www.ionos.de/terms-gtc/terms-privacy">https://www.ionos.de/terms-gtc/terms-privacy</a>.
          </v-col>
          <v-col
            cols="12"
          >
            Die Verwendung von IONOS erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein
            berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art.
            6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
          </v-col>
          <v-col
            cols="12"
            class="font-weight-bold"
          >
            Auftragsverarbeitung
          </v-col>
          <v-col
            cols="12"
          >
            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit der oben genannten Anbieterin geschlossen.
            Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass
            dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter
            Einhaltung der DSGVO verarbeitet.
          </v-col>
          <v-col
            cols="12"
            class="text-h5 text-md-h4"
          >
            3. Allgemeine Hinweise und Pflichtinformationen
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Datenschutz
          </v-col>
          <v-col
            cols="12"
          >
            Die Betreiber*innen dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
            personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie
            dieser Datenschutzerklärung.
          </v-col>
          <v-col
            cols="12"
          >
            Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
            Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
            Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie
            und zu welchem Zweck das geschieht.
          </v-col>
          <v-col
            cols="12"
          >
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail)
            Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Hinweis zur verantwortlichen Stelle
          </v-col>
          <v-col
            cols="12"
          >
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
          </v-col>
          <v-col
            cols="12"
          >
            Christine Ball<br />
            Amselgasse 6<br />
            69121 Heidelberg<br />
            <br />
            Telefon: +49 6221 411554<br />
            E-Mail: c@ball-design.de
          </v-col>
          <v-col
            cols="12"
          >
            Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über
            die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.)
            entscheidet.
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Speicherdauer
          </v-col>
          <v-col
            cols="12"
          >
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben
            Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
            berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen,
            werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer
            personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im
            letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </v-col>
          <v-col
            cols="12"
          >
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
            bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen
            Direktwerbung (Art. 21 DSGVO)
          </v-col>
          <v-col
            cols="12"
          >
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO
            ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN
            SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN
            WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
            PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
            ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN,
            WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
            SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
            NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE
            VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON
            RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
          </v-col>
          <v-col
            cols="12"
          >
            WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
            SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE
            BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
            EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
            VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
            ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH
            NACH ART. 21 ABS. 2 DSGVO).
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </v-col>
          <v-col
            cols="12"
          >
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
            verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Recht auf Datenübertragbarkeit
          </v-col>
          <v-col
            cols="12"
          >
            Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
            automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
            verlangen, erfolgt dies nur, soweit es technisch machbar ist.
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Auskunft, Löschung und Berichtigung
          </v-col>
          <v-col
            cols="12"
          >
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
            Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
            Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie
            zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Recht auf Einschränkung der Verarbeitung
          </v-col>
          <v-col
            cols="12"
          >
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
            folgenden Fällen:
          </v-col>
          <v-col
            cols="12"
          >
            <ul>
              <li>
                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
                in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
                Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>
              <li>
                Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie
                statt der Löschung die Einschränkung der Datenverarbeitung verlangen.
              </li>
              <li>
                Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung,
                Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>
              <li>
                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen
                Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen
                überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
                zu verlangen.
              </li>
            </ul>
          </v-col>
          <v-col
            cols="12"
          >
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von
            ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder
            juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder
            eines Mitgliedstaats verarbeitet werden.
          </v-col>
          <v-col
            cols="12"
            class="text-h5 text-md-h4"
          >
            4. Datenerfassung auf dieser Website
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Server-Log-Dateien
          </v-col>
          <v-col
            cols="12"
          >
            Die Providerin der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
          </v-col>
          <v-col
            cols="12"
          >
            <ul>
              <li>
                Browsertyp und Browserversion
              </li>
              <li>
                verwendetes Betriebssystem
              </li>
              <li>
                Referrer URL
              </li>
              <li>
                Hostname des zugreifenden Rechners
              </li>
              <li>
                Uhrzeit der Serveranfrage
              </li>
              <li>
                IP-Adresse
              </li>
            </ul>
          </v-col>
          <v-col
            cols="12"
          >
            Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
          </v-col>
          <v-col
            cols="12"
          >
            Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
            ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website –
            hierzu müssen die Server-Log-Files erfasst werden.
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            Anfrage per E-Mail, Telefon oder Telefax
          </v-col>
          <v-col
            cols="12"
          >
            Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus
            hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens
            bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
          </v-col>
          <v-col
            cols="12"
          >
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit
            der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der
            effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
          </v-col>
          <v-col
            cols="12"
          >
            Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
            auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
            (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
            insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
          </v-col>
          <v-col
            cols="12"
            class="text-h5 text-md-h4"
          >
            5. Analyse-Tools und Werbung
          </v-col>
          <v-col
            cols="12"
            class="text-h6 text-md-h5"
          >
            IONOS WebAnalytics
          </v-col>
          <v-col
            cols="12"
          >
            Diese Website nutzt die Analysedienste von IONOS WebAnalytics (im Folgenden: IONOS). Anbieterin ist die
            1&1 IONOS SE, Elgendorfer Straße 57, D – 56410 Montabaur. Im Rahmen der Analysen mit IONOS können
            u. a. Besuchszahlen und –verhalten (z. B. Anzahl der Seitenaufrufe, Dauer eines Webseitenbesuchs,
            Absprungraten), Besucher*innenquellen (d. h., von welcher Seite der/die Besucher*in kommt), Besuchsstandorte sowie
            technische Daten (Browser- und Betriebssystemversionen) analysiert werden. Zu diesem Zweck speichert
            IONOS insbesondere folgende Daten:
          </v-col>
          <v-col
            cols="12"
          >
            <ul>
              <li>
                Referrer (zuvor besuchte Webseite)
              </li>
              <li>
                angeforderte Webseite oder Datei
              </li>
              <li>
                Browsertyp und Browserversion
              </li>
              <li>
                verwendetes Betriebssystem
              </li>
              <li>
                verwendeter Gerätetyp
              </li>
              <li>
                Uhrzeit des Zugriffs
              </li>
              <li>
                IP-Adresse in anonymisierter Form (wird nur zur Feststellung des Orts des Zugriffs verwendet)
              </li>
            </ul>
          </v-col>
          <v-col
            cols="12"
          >
            Die Datenerfassung erfolgt laut IONOS vollständig anonymisiert, sodass sie nicht zu einzelnen Personen
            zurückverfolgt werden kann. Cookies werden von IONOS WebAnalytics nicht gespeichert.
          </v-col>
          <v-col
            cols="12"
          >
            Die Speicherung und Analyse der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Die
            Websitebetreiberin hat ein berechtigtes Interesse an der statistischen Analyse des Nutzerverhaltens, um
            sowohl sein Webangebot als auch seine Werbung zu optimieren. Sofern eine entsprechende Einwilligung
            abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
            Einwilligung ist jederzeit widerrufbar.
          </v-col>
          <v-col
            cols="12"
          >
            Weitere Informationen zur Datenerfassung und Verarbeitung durch IONOS WebAnalytics entnehmen Sie
            der Datenschutzerklaerung von IONOS unter folgendem Link:
          </v-col>
          <v-col
            cols="12"
          >
            <a target="_blank" class="no-text-decoration" href="https://www.ionos.de/terms-gtc/index.php?id=6">https://www.ionos.de/terms-gtc/index.php?id=6</a>
          </v-col>
          <v-col
            cols="12"
            class="font-weight-bold"
          >
            Auftragsverarbeitung
          </v-col>
          <v-col
            cols="12"
          >
            Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit der oben genannten Anbieterin geschlossen.
            Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass
            dieser die personenbezogenen Daten unserer Websitebesucher*innen nur nach unseren Weisungen und unter
            Einhaltung der DSGVO verarbeitet.
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Contact',
    data: () => ({
    }),
    methods: { }
  }
</script>

<style scoped>
  a.no-text-decoration {
    display: inline-block;
    position: relative;
    text-decoration: none;
    color: inherit;
    cursor: pointer !important;
    z-index: 1;
    transition: color 0.2s ease-out
  }
  a.no-text-decoration:hover {
    color: white;
  }
  a.no-text-decoration::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 2px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #c8242b;
    z-index: -1;
    transition: height 0.2s ease-out;
  }
  a.no-text-decoration:hover::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    bottom: 0px;
    background-color: #c8242b;
  }
</style>

