<template>
  <v-container>
    <v-row
      class="error-container pb-12"
      justify="center"
      :style="{
        'margin-top': computedErrorContainerMarginTop
      }"
    >
      <v-card
        color="#FFFFFF"
        class="px-4 px-sm-5 px-md-6 px-lg-7 px-xl-8 pt-4 pb-4 pb-sm-5 pb-md-6 pb-lg-7 pb-xl-8"
        elevation="0"
      >
        <span
          class="error-text"
          :style="{
            'font-size': computedErrorTextSize,
            'line-height': computedErrorTextSize
          }"
        >
          OOPS!
        </span>
        <div
          class="error-note pa-1 pa-sm-1 pa-md-2 pa-lg-3 pa-xl-3"
          :style="{
            'font-size': computedErrorNoteSize
          }"
        >
          <center>
            <div>
              404 - Diese Seite habe ich leider nicht im Angebot.
            </div>
            <div
              :style="{
                'font-size': computedErrorNoteSmallSize
              }"
            >
              (die anderen Seiten sind sowieso viel schöner...)
            </div>
          </center>
        </div>
      </v-card>
      <v-col
        cols="12"
        align="center"
        class="pt-8"
      >
        <span
          class="error-link-container pa-4 error-link-container-center"
        >
          <span
            class="text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 error-link"
            @click="openHome"
          >
            Zur Startseite
          </span>
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Error404',
  components: {
  },
  data () {
    return { }
  },
  mounted () {
  },
  methods: {
    openHome () {
      this.$router.push({ name: 'Home' })
    }
  },
  computed: {
    computedErrorContainerMarginTop () {
      if (this.$vuetify.breakpoint.name === 'xl') {
        return '18%'
      } else if (this.$vuetify.breakpoint.name === 'lg') {
        return '23%'
      } else if (this.$vuetify.breakpoint.name === 'md') {
        return '28%'
      } else if (this.$vuetify.breakpoint.name === 'sm') {
        return '33%'
      } else {
        return '38%'
      }
    },
    computedErrorTextSize () {
      if (this.$vuetify.breakpoint.name === 'xl') {
        return '14rem !important'
      } else if (this.$vuetify.breakpoint.name === 'lg') {
        return '14rem !important'
      } else if (this.$vuetify.breakpoint.name === 'md') {
        return '12rem !important'
      } else if (this.$vuetify.breakpoint.name === 'sm') {
        return '8rem !important'
      } else {
        return '5.5rem !important'
      }
    },
    computedErrorNoteSize () {
      if (this.$vuetify.breakpoint.name === 'xl') {
        return '24px !important'
      } else if (this.$vuetify.breakpoint.name === 'lg') {
        return '24px !important'
      } else if (this.$vuetify.breakpoint.name === 'md') {
        return '20px !important'
      } else if (this.$vuetify.breakpoint.name === 'sm') {
        return '14px !important'
      } else {
        return '10px !important'
      }
    },
    computedErrorNoteSmallSize () {
      if (this.$vuetify.breakpoint.name === 'xl') {
        return '12px !important'
      } else if (this.$vuetify.breakpoint.name === 'lg') {
        return '12px !important'
      } else if (this.$vuetify.breakpoint.name === 'md') {
        return '10px !important'
      } else if (this.$vuetify.breakpoint.name === 'sm') {
        return '8px !important'
      } else {
        return '7px !important'
      }
    }
  }
}
</script>

<style scoped>
.error-container {
  position: relative;
}
.error-text {
  color: #989284 !important;
}

.error-note {
  position: absolute;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  background-color: #FFFFFF;
  color:  #989284 !important;
  white-space: nowrap;
}

.error-link-container {
  position: absolute;
  display: inline-block;
  bottom: 0px;
  white-space: nowrap;
}
.error-link-container-right {
  right: 0px;
}
.error-link-container-center {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

.error-link-disabled {
  position: relative;
  cursor: default;
  color: #a5a5a5;
  transition: color 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out;
  z-index: 1;
}

.error-link {
  position: relative;
  cursor: pointer;
  color: #555555;
  transition: color 0.3s ease-in-out;
  -webkit-transition: color 0.3s ease-in-out;
  z-index: 1;
}
.error-link:hover {
  color: #ffffff;
}

.error-link::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 2px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #c8242b;
  z-index: -1;
  transition: height 0.2s ease-out;
  -webkit-transition: height 0.2s ease-out;
}
.error-link:hover::before {
  content: "";
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  bottom: 0px;
  background-color: #c8242b;
}
</style>