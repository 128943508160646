<template>
  <v-container>
    <v-row
      class="py-12 px-4 px-sm-8 px-md-10 px-lg-12"
    >
      <v-col
        cols="12"
        class="pb-12 font-weight-medium text-h4 text-sm-h3 text-md-h2 text-lg-h2 text-xl-h1"  
      >
        Portfolio
      </v-col>
      <v-col
        cols="12"
      >
        <v-sheet
          light
          color="#f2f3f4"
          class="pa-4"
        >
          <v-col
            cols="12"
            class="text-h6 text-md-h5 pb-0"
          >
            Grafik-Design
          </v-col>
          <GalleryTile
            v-if="graphicsDesignGalleryElements.length > 0"
            :photoElements="graphicsDesignGalleryElements"
          />
        </v-sheet>
      </v-col>
      <v-col
        cols="12"
      >
        <v-sheet
          light
          color="#f2f3f4"
          class="pa-4"
        >
          <v-col
            cols="12"
            class="text-h6 text-md-h5 pb-0"
          >
            Illustration
          </v-col>
          <GalleryTile
            v-if="illustrationGalleryElements.length > 0"
            :photoElements="illustrationGalleryElements"
          />
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GalleryTile from './components/GalleryTile';

  export default {
    name: 'Portfolio',
    components: {
      GalleryTile
    },
    data: () => ({
      graphicsDesignGalleryElements: [
        {
          previewSrc: '/img_portfolio/grafik/div_logos.svg',
          src: '/img_portfolio/grafik/full/div_logos.svg',
          title: 'Diverse Logos',
          descriptionTitle: 'Logobeispiele',
          description: null,
          href: null
        },
        {
          previewSrc: '/img_portfolio/grafik/wiss_verlag.jpg',
          src: '/img_portfolio/grafik/full/wiss_verlag.jpg',
          title: 'Wissenschaftlicher Verlag',
          descriptionTitle: 'Springer Medizin Verlag',
          description: 'Imageanzeigen | Anzeigen zur Abonennt*innen-, Gutachter*innen- und Autor*innenwerbung | Maßnahmen zur Kund*innenbindung',
          href: null
        },
        {
          previewSrc: '/img_portfolio/grafik/medizintechnik.jpg',
          src: '/img_portfolio/grafik/full/medizintechnik.jpg',
          title: 'Medizintechnik',
          descriptionTitle: 'Arca Medica (Medizinische Implantate)',
          description: 'Erscheinungsbild | Informationsmaterial für Anwender*innen und Patient*innen | Illustrationen der Operationsvorgänge',
          href: null
        },
        {
          previewSrc: '/img_portfolio/grafik/heilberuf.jpg',
          src: '/img_portfolio/grafik/full/heilberuf.jpg',
          title: 'Heilberuf',
          descriptionTitle: 'Priew und Ball Physiotherapie',
          description: 'Logo | Geschäftspapiere | Informationsflyer | Gutscheine',
          href: null
        },
        {
          previewSrc: '/img_portfolio/grafik/pharmazie.jpg',
          src: '/img_portfolio/grafik/full/pharmazie.jpg',
          title: 'Pharmazie und Kosmetik',
          descriptionTitle: 'Hofapotheken',
          description: 'Diverse Werbemittel',
          href: null
        },
        {
          previewSrc: '/img_portfolio/grafik/landwirtschaft.jpg',
          src: '/img_portfolio/grafik/full/landwirtschaft.jpg',
          title: 'Landwirtschaft',
          descriptionTitle: 'BV-Hoechstaedt',
          description: 'Anzeigen und Werbemittel für die Bullenzucht',
          href: null
        },
        {
          previewSrc: '/img_portfolio/grafik/paedagogik.jpg',
          src: '/img_portfolio/grafik/full/paedagogik.jpg',
          title: 'Pädagogik',
          descriptionTitle: 'Hans-Lebrecht-Schule Ulm',
          description: 'Lehrmittel-Fächer zum Start in die Schule und dazugehöriges Informationsplakat',
          href: null
        },
        {
          previewSrc: '/img_portfolio/grafik/kultur.svg',
          src: '/img_portfolio/grafik/full/kultur.jpg',
          title: 'Kultur',
          descriptionTitle: 'Die Dialektiker (Kabarett)',
          description: 'Entwicklung eines figürlichen Logos | Diverse Werbemittel',
          href: null
        },
        {
          previewSrc: '/img_portfolio/grafik/lokalpolitik.jpg',
          src: '/img_portfolio/grafik/full/lokalpolitik.jpg',
          title: 'Lokalpolitik',
          descriptionTitle: 'Bunte Linke',
          description: 'Litfaß-Plakate zur Gemeinderatswahl',
          href: null
        },
        {
          previewSrc: '/img_portfolio/grafik/oeffentlichkeitsarbeit.jpg',
          src: '/img_portfolio/grafik/full/oeffentlichkeitsarbeit.jpg',
          title: 'Öffentlichkeitsarbeit',
          descriptionTitle: 'Hans-Lebrecht-Schule-Ulm',
          description: '„Stolpersteine“ | Informationsbroschüre anlässlich einer Stolperstein-Verlegung',
          href: null
        },
        {
          previewSrc: '/img_portfolio/grafik/dokumentation.jpg',
          src: '/img_portfolio/grafik/full/dokumentation.jpg',
          title: 'Dokumentation',
          descriptionTitle: 'Hans Prockl',
          description: '„Menschen aus der Region Buchbach-Schwindegg“ | Buchlayout',
          href: null
        }
      ],
      illustrationGalleryElements: [
        {
          previewSrc: '/img_portfolio/illustration/zeitung.jpg',
          src: '/img_portfolio/illustration/full/zeitungskarikatur.jpg',
          title: 'Zeitungskarikatur',
          descriptionTitle: 'Rhein-Neckar-Zeitung',
          description: 'Karikaturen zu Aktuellem aus dem Lokalgeschehen',
          href: 'https://www.rnz.de/nachrichten/heidelberg/karikatur-lokal_galerie,-Die-Woche-faengt-ja-gut-an-Die-Heidelberger-Lokalkarikatur-_mediagalid,330.html'
        },
        {
          previewSrc: '/img_portfolio/illustration/anzeigenillu.jpg',
          src: '/img_portfolio/illustration/full/anzeigenillustration.jpg',
          title: 'Anzeigenillustration',
          descriptionTitle: 'Springer Medizin Verlag',
          description: 'Illustration und Gestaltung von Anzeigen zu Abonnentenwerbung',
          href: null
        },
        {
          previewSrc: '/img_portfolio/illustration/zeitschriftenillu.jpg',
          src: '/img_portfolio/illustration/full/zeitschriftenkarikatur.jpg',
          title: 'Zeitschriftenkarikatur',
          descriptionTitle: 'KU Gesundheitsmanagement',
          description: 'Karikaturen zu Aktuellem aus dem Krankenhausmanagement',
          href: null
        },
        {
          previewSrc: '/img_portfolio/illustration/buchgestaltung.jpg',
          src: '/img_portfolio/illustration/full/buchillu_layout.jpg',
          title: 'Buchgestaltung und Illustration',
          descriptionTitle: 'Anna Münkel',
          description: '„Spiegeldei“ | Gedichtband | Layout und Illustrationen',
          href: null
        },
        {
          previewSrc: '/img_portfolio/illustration/buchillu.jpg',
          src: '/img_portfolio/illustration/full/buchillu.jpg',
          title: 'Buchillustration',
          descriptionTitle: 'Beltz Verlag',
          description: 'Dr. med. Michael Hauch und Regine Hauch: „Ihr unbekanntes Superorgan – Alles über das Immunsystem“ | Illustrationen',
          href: null
        },
        {
          previewSrc: '/img_portfolio/illustration/illu_erscheinungsbild.jpg',
          src: '/img_portfolio/illustration/full/illu_erscheinungsbild.jpg',
          title: 'Illustration im Erscheinungsbild',
          descriptionTitle: 'SOS Kinder- und Jugendhilfe',
          description: 'Informationsmaterial für Eltern, Jugendliche und Kinder | Konzept, Layout und Illustration',
          href: null
        },
        {
          previewSrc: '/img_portfolio/illustration/comic.jpg',
          src: '/img_portfolio/illustration/full/comic.jpg',
          title: 'Comic',
          descriptionTitle: 'Mine Mark Foundation',
          description: 'Mehrsprachiger Comic für Kinder in Kriegsgebieten zu Verhaltensregeln und dem Erkennen von Minen',
          href: 'https://www.minemark.org/'
        },
        {
          previewSrc: '/img_portfolio/illustration/zeitschriftencover.jpg',
          src: '/img_portfolio/illustration/full/zeitschriftencover.jpg',
          title: 'Zeitschriftencover',
          descriptionTitle: 'Kaden Verlag',
          description: 'Titelillustrationen für medizinische Zeitschriften',
          href: null
        },
        {
          previewSrc: '/img_portfolio/illustration/werbefigur.jpg',
          src: '/img_portfolio/illustration/full/werbefigur.jpg',
          title: 'Werbefigur',
          descriptionTitle: 'Corps Thuringia',
          description: 'Werbefigur der Website, die durch verschiedene Themenbereiche führt',
          href: null
        },
        {
          previewSrc: '/img_portfolio/illustration/plakatillu.jpg',
          src: '/img_portfolio/illustration/full/plakatillustration.jpg',
          title: 'Plakatillustration',
          descriptionTitle: 'Medizinische Fakultät Heidelberg',
          description: 'Plakat für das Pädiatrische Patenschaftsprojekt Prävention (PäPP) der Medizinischen Fakultät Heidelberg',
          href: null
        },
        {
          previewSrc: '/img_portfolio/illustration/wissenschaftillu.jpg',
          src: '/img_portfolio/illustration/full/wissenschaft.jpg',
          title: 'Wissenschaftliche Illustration',
          descriptionTitle: 'Arca Medica (Medizinische Implantate)',
          description: 'Operationszeichnungen',
          href: null
        }
      ]
    }),
  }
</script>
