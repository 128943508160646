<template>
  <v-col
    cols="12"
  >
    <v-sheet
      light
      color="#f2f3f4"
      class="py-4 px-0"
      height="100%"
    >
      <v-row>
        <v-col
          v-for="(photo, i) of photoElements"
          :key="i"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          align="center"
        >
          <v-hover
            v-slot="{ hover }"
          >
            <div
              style="cursor: pointer;"
            >
            <v-card
              elevation="0"
              class="gallery-card pointer pa-0"
              tile
            >
              <v-img
                contain
                :src="photo.previewSrc"
                @click="clickedPic = i"
                :aspect-ratio="1.6"
                :style="{
                  'opacity': hover ? '0.65' : '1.0',
                  'transition': 'opacity 0.3s ease-in-out'
                }"
              >
              </v-img>
            </v-card>
            <div
              class="mt-2 pb-1 px-0 text-subtitle-2"
              style="text-align: center;"
              @click="clickedPic = i"
            >
              {{ photo.title }}
            </div>
            </div>
          </v-hover>
        </v-col>
      </v-row>
    </v-sheet>
    <v-dialog
      v-model="showClickedPic"
      fullscreen
    >
      <v-sheet
        width="100%"
        height="100%"
        color="rgba(0,0,0,0.8)"
        class="d-flex"
        :class="{ 'pa-4': !$vuetify.breakpoint.mdAndUp, 'pa-12': $vuetify.breakpoint.mdAndUp }"

      >
        <v-hover
          v-slot="{ hover }"
        >
          <v-btn
            color="red"
            fab
            @click="clickedPic = undefined"
            :elevation="hover ? 12 : 3"
            small
            class="gallery-dialog-close-button"
          >
            <v-icon
              color="white"
            >
              mdi-close
            </v-icon>
          </v-btn>
        </v-hover>
        <v-row
          no-gutters
          style="position: relative; max-width: 100%; height: 100%;"
        >
          <v-col
            cols="12"
            style="height: 100%;"
            class="d-flex"
          >
            <v-img
              :src="clickedPic !== undefined ? photoElements[clickedPic].src : ''"
              max-height="100%"
              contain
              position="center center"
            >
              <v-container
                class="fill-height pa-0"
                fluid
              >
                <div
                  class="gallery-dialog-navigaion"
                >
                  <v-col
                    cols="6"
                  >
                    <v-hover
                      v-slot="{ hover }"
                    >
                      <v-btn
                        fab
                        height="36"
                        width="36"
                        @click="clickedPic--"
                        :disabled="clickedPic === undefined || clickedPic === 0"
                        color="#00000088"
                        :elevation="hover ? 12 : 3"
                      >
                        <v-icon
                          color="white"
                        >
                          mdi-chevron-left
                        </v-icon>
                      </v-btn>
                    </v-hover>
                  </v-col>
                  <v-col
                    cols="6"
                    class="text-right"
                  >
                    <v-hover
                      v-slot="{ hover }"
                    >
                      <v-btn
                        fab
                        height="36"
                        width="36"
                        @click="clickedPic++"
                        :disabled="clickedPic === undefined || clickedPic === photoElements.length - 1"
                        color="#00000088"
                        :elevation="hover ? 12 : 3"
                      >
                        <v-icon
                          color="white"
                        >
                          mdi-chevron-right
                        </v-icon>
                      </v-btn>
                    </v-hover>
                  </v-col>
                </div>
              </v-container>
            </v-img>
          </v-col>
          <v-col
            cols="12"
            style="position: absolute; width: 100%; bottom: 0px;"
          >
              <v-row
                v-if="clickedPic !== undefined"
                class="align-self-end"
                no-gutters
              >
                <v-col
                  class="text-left"
                  cols="12"
                  style="backdrop-filter: blur(4px);"
                >
                  <span
                    v-if="photoElements[clickedPic].descriptionTitle !== null"
                    style="display: block; background-color: #ffffffbb; padding: 6px 12px 6px 12px;"
                    :class="{
                      'text-h6': $vuetify.breakpoint.xs,
                      'text-h5': !$vuetify.breakpoint.xs,
                    }"
                  >
                    {{ photoElements[clickedPic].descriptionTitle }}
                  </span>
                  <span
                    v-if="photoElements[clickedPic].description !== null"
                    style="display: block; background-color: #ffffffbb; padding: 6px 12px 6px 12px;"
                    :class="{
                      'text-body-2': $vuetify.breakpoint.xs
                    }"
                  >
                    <span
                      style="display: inline-block;"
                    >
                      {{ photoElements[clickedPic].description }}
                    </span>
                    <v-hover
                      v-if="photoElements[clickedPic].href !== null"
                      v-slot="{ hover }"
                      class="ml-4"
                    >
                      <v-btn
                        :href="photoElements[clickedPic].href"
                        target="_blank"
                        color="transparent"
                        :elevation="hover ? 1 : 0"
                      >
                        Mehr
                        <v-icon
                        >
                          mdi-launch
                        </v-icon>
                      </v-btn>
                    </v-hover>
                  </span>
                </v-col>
              </v-row>
          </v-col>
        </v-row>
      </v-sheet>
    </v-dialog>
  </v-col>
</template>

<script>

export default {
  name: 'GalleryTile',
  components: {
  },
  props: {
    photoElements: Array
  },
  data () {
    return {
      clickedPic: undefined,
      showClickedPic: false
    }
  },
  mounted () { },
  methods: {
  },
  computed: {
  },
  watch: {
    clickedPic () {
      if (this.clickedPic !== undefined) {
        this.showClickedPic = true
      } else {
        this.showClickedPic = false
      }
    }
  }
}
</script>

<style scoped>
  .gallery-link {
    position: relative;
    display: inline-block;
    cursor: pointer;
    color: #555555;
    transition: color 0.3s ease-in-out;
    z-index: 1;
  }
  .gallery-link:hover {
    color: #ffffff;
  }

  .gallery-link::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 2px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #c8242b;
    z-index: -1;
    transition: height 0.2s ease-out;
  }
  .gallery-link:hover::before {
    content: "";
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 100%;
    bottom: 0px;
    background-color: #c8242b;
  }

  .gallery-dialog-close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 10;
  }
  .gallery-dialog-navigaion {
    position: absolute;
    display: flex;
    top: 50%; left: 0px;
    right: 0px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
  }
  .gallery-card {
    transition: box-shadow 0.3s ease-in-out;
  }
</style>
