var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"error-container pb-12",style:({
      'margin-top': _vm.computedErrorContainerMarginTop
    }),attrs:{"justify":"center"}},[_c('v-card',{staticClass:"px-4 px-sm-5 px-md-6 px-lg-7 px-xl-8 pt-4 pb-4 pb-sm-5 pb-md-6 pb-lg-7 pb-xl-8",attrs:{"color":"#FFFFFF","elevation":"0"}},[_c('span',{staticClass:"error-text",style:({
          'font-size': _vm.computedErrorTextSize,
          'line-height': _vm.computedErrorTextSize
        })},[_vm._v(" OOPS! ")]),_c('div',{staticClass:"error-note pa-1 pa-sm-1 pa-md-2 pa-lg-3 pa-xl-3",style:({
          'font-size': _vm.computedErrorNoteSize
        })},[_c('center',[_c('div',[_vm._v(" 404 - Diese Seite habe ich leider nicht im Angebot. ")]),_c('div',{style:({
              'font-size': _vm.computedErrorNoteSmallSize
            })},[_vm._v(" (die anderen Seiten sind sowieso viel schöner...) ")])])],1)]),_c('v-col',{staticClass:"pt-8",attrs:{"cols":"12","align":"center"}},[_c('span',{staticClass:"error-link-container pa-4 error-link-container-center"},[_c('span',{staticClass:"text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1 error-link",on:{"click":_vm.openHome}},[_vm._v(" Zur Startseite ")])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }