var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}]},[_c('v-app-bar',{style:({
      'transform': 'translateY('+ _vm.computedAppBarTranslateY +')'
    }),attrs:{"app":"","color":"#242021","dark":"","height":_vm.windowHeight + 100,"elevation":"16"}},[_c('v-row',[_c('v-col',{style:({
          'height': _vm.windowHeight+'px' 
        }),attrs:{"cols":"12"}},[_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","height":"100"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleMenu(false)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-close-thick ")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',_vm._l((_vm.menuItems),function(menuItem,i){return _c('v-col',{key:i,staticClass:"text-center text-h4",class:{
                  'menu-item': menuItem.routeName !== _vm.currentRouteName,
                  'menu-item-active': menuItem.routeName === _vm.currentRouteName
                },attrs:{"cols":"12"},on:{"click":function($event){menuItem.routeName === _vm.currentRouteName ? null : _vm.openPage(menuItem.routeName)}}},[_vm._v(" "+_vm._s(menuItem.text)+" ")])}),1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","height":"100"}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleMenu(true)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-menu ")])],1),_c('v-spacer'),_c('v-img',{style:({
              'cursor': _vm.currentRouteName === 'Home' ? 'default' : 'pointer'
            }),attrs:{"contain":"","src":"/logos/logo.svg","height":_vm.computedLogoHeight,"max-width":"75%","position":"center right"},on:{"click":function($event){_vm.currentRouteName === 'Home' ? null : _vm.openPage('Home')}}})],1)],1)],1),_c('span',{staticClass:"kontakt-stoerer elevation-2 pa-3"},[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"icon":"","href":"mailto:c@ball-design.de","target":"_blank","small":""}},[_c('v-icon',{attrs:{"color":"#c8242b"}},[_vm._v(" mdi-email ")])],1)],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"icon":"","href":"tel:+496221411554","target":"_blank","small":""}},[_c('v-icon',{attrs:{"color":"#c8242b"}},[_vm._v(" mdi-phone ")])],1)],1)],1)],1),_c('v-main',{staticStyle:{"padding-top":"100px"}},[_c('router-view')],1),_c('span',{staticClass:"impress-link-container pa-4",class:{
      'impress-link-container-center': _vm.$vuetify.breakpoint.xsOnly,
      'impress-link-container-right': !_vm.$vuetify.breakpoint.xsOnly,
    }},[_c('span',{staticClass:"text-body-2 text-sm-body-1 text-md-body-1 text-lg-body-1 text-xl-body-1",class:{
        'impress-link': _vm.currentRouteName !== 'Contact',
        'impress-link-disabled': _vm.currentRouteName === 'Contact'
      },on:{"click":function($event){_vm.currentRouteName === 'Contact' ? null : _vm.openPage('Contact')}}},[_vm._v(" IMPRESSUM | DATENSCHUTZ ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }