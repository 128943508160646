<template>
  <v-container>
    <v-row
      class="py-12 px-4 px-sm-8 px-md-0 px-lg-0 px-xl-0"
    >
      <v-col
        class="
          col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4
          pb-12 pb-sm-12 pb-md-0 pb-lg-0 pb-xl-0
          font-weight-medium
          text-h4 text-sm-h3 text-md-h2 text-lg-h2 text-xl-h1
        "  
      >
        Zur Person
      </v-col>
      <v-col
        class="col-12 col-sm-8 col-md-5 col-lg-5 col-xl-5"
      >
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            class="font-weight-bold"
          >
            Christine Ball<br />
            Diplom Grafik-Designerin (FH)
          </v-col>
          <v-col
            cols="12"
            class="pt-6"
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                class="font-weight-bold"
              >
              Studium
              </v-col>
              <v-col
                cols="12"
                class="pt-2"
              >
                Grafik-Design an der Fachhochschule für Gestaltung Mannheim
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            class="pt-6"
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                class="font-weight-bold"
              >
                Berufstätigkeit
              </v-col>
              <v-col
                cols="12"
                class="pt-2"
              >
                Anstellung als Grafikerin und Art Director in Werbeagenturen
              </v-col>
              <v-col
                cols="12"
                class="pt-2"
              >
                Seit 1992 selbständig
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            class="pt-6"
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                class="font-weight-bold"
              >
                Zusatzqualifikationen
              </v-col>
              <v-col
                cols="12"
                class="pt-2"
              >
                Teilnahme in den Klassen Illustration bei Luis Murschetz und Radierung bei Rudolf Hradil,
                Sommerakademie für Bildende Kunst, Salzburg
              </v-col>
              <v-col
                cols="12"
                class="pt-2"
              >
                Teilnahme an Kursen Werbetext und Direktmarketing,
                Siegfried-Vögele-Institut
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            class="pt-6"
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                class="font-weight-bold"
              >
                Wettbewerb
              </v-col>
              <v-col
                cols="12"
                class="pt-2"
              >
                1. Preis zur Gestaltung eines Logos für das Heidelberger
                Interventionsmodell gegen Gewalt, Stadt Heidelberg, 2003
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3"
        align="center"
      >
        <v-img
          contain
          src="/portraitfoto.jpg"
          :width="$vuetify.breakpoint.xsOnly ? '80%' : '100%'"
        >
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'AboutMe',
    data: () => ({
    }),
  }
</script>
