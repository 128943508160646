<template>
  <v-container>
    <v-row
      class="py-12 px-4 px-sm-8 px-md-10 px-lg-12"
    >
      <v-col
        cols="12"
        class="pb-12 font-weight-medium text-h4 text-sm-h3 text-md-h2 text-lg-h2 text-xl-h1"  
      >
        Leistung
      </v-col>
      <v-col
        cols="12"
      >
        Ich biete Grafik-Design und Illustration im Printbereich an.
        <br />
        Meine Arbeit umfasst Konzeption und Realisierung eines visuellen Auftritts, der eine klare Botschaft an die Zielgruppe richtet.
        Das beinhaltet neben der Schaffung von Logo und der gesamten Geschäftsausstattung alle Werbemedien in gedruckter
        Form. Dazu gehört auch die textliche Ansprache, beispielsweise die Entwicklung einer Headline. Ziel ist, im Verbund von Bild
        und Text, eine passgenaue Aussage aufzubauen.
      </v-col>
      <v-col
        cols="12"
      >
        Neben dem Einsatz von Fotografien kann ich zielgerichtet Illustrationen entwickeln. Diese Möglichkeit ist ein Alleinstellungsmerkmal gegenüber der Flut sich ähnelnden Fotomaterials, das in den letzten Jahren unsere Werbelandschaft kennzeichnet.
        Wo es das Thema zulässt, sind Illustrationen als Teil eines Werbemediums eine sympathische Möglichkeit, verstärkt Aufmerksamkeit zu wecken.
      </v-col>
      <v-col
        cols="12"
      >
        Die illustrative Arbeit umfasst zudem Arbeiten zu vorgegebenen redaktionellen Themen, ebenso wie regelmäßig erscheinende
        Cartoons oder die Entwicklung von Werbefiguren.
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'Service',
    data: () => ({
    }),
  }
</script>
